import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 70,
  },
  path: {
    fill: '#00a0e6',
  },
});
export const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.4646 143.4898"
    >
      <path
        className={classes.path}
        d="M197.5438,118.3649c18.5564,0,26.0413-5.926,28.0685-20.4293l9.3562-66.746h-19.0242l-9.3562,67.3698 c-0.4678,3.5868-2.6509,5.1463-7.0171,5.1463s-6.0815-1.5595-5.6137-5.1463l9.5121-67.3698h-19.0242l-9.3562,66.746 C173.0618,112.5948,179.6112,118.3649,197.5438,118.3649z M141.5628,87.0193l11.2274-41.4823h1.7153l-3.1187,41.4823H141.5628z M115.2096,116.3376h18.5564l4.0543-15.127h12.4749l-1.0916,15.127h18.7123l3.5865-85.1479h-31.3432L115.2096,116.3376z M57.5132,116.3376H75.134l5.9256-41.9501h1.0916l10.2918,41.9501h17.9326l12.0071-85.1479h-17.6208l-6.2374,44.1334h-1.0916 l-9.9799-44.1334H69.5203L57.5132,116.3376z M0,116.3376h19.0242l5.3018-37.2717h1.8712l7.1731,37.2717h19.3361L43.974,71.1125 L63.31,31.1897H44.1299L27.6007,65.1865h-1.4034L35.3975,0H16.3733L0,116.3376z M247.7308,143.4898l9.5365-67.6988h18.4005 l2.0272-14.3473h-18.4005l2.1831-15.9067h19.9598l2.0272-14.3473h-38.984l-15.7739,112.3001H247.7308z"
      />
    </svg>
  );
};

export default LogoFull;
