import { Header, Page, Content } from '@backstage/core-components';
import {
  HeaderWorldClock,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageRecentlyVisited,
  WelcomeTitle,
  ClockConfig,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { tools, useLogoStyles } from './shared';
import { InfoCard } from '@backstage/core-components';
import { LogoFull } from '../Root/LogoFull';
import { FeatureFlagged } from '@backstage/core-app-api';
// import { KnaufJiraDashboard } from '@internal/backstage-plugin-knauf-jira-dashboard';

import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { JiraUserIssuesCard } from '@internal/backstage-plugin-knauf-jira-dashboard';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const clockConfigs: ClockConfig[] = [
  {
    label: 'CET',
    timeZone: 'Europe/Berlin',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'STO',
    timeZone: 'Europe/Stockholm',
  },
  {
    label: 'NYC',
    timeZone: 'America/New_York',
  },
]

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title={<WelcomeTitle />} pageTitleOverride="Home">
          <HeaderWorldClock
            clockConfigs={clockConfigs}
            customTimeFormat={timeFormat}
          />
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={2}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFull/>}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={4} spacing={2}>
              <Grid item xs={12}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12}>
                <HomePageToolkit tools={tools} />
              </Grid>
            </Grid>
            <Grid container item xs={4} spacing={3}>
              <FeatureFlagged with="homepage-calendar">
                <Grid item xs={12}>
                 <InfoCard title="Microsoft calendar">
                  Lorem ipsum dolor sit amet, officia excepteur ex fugiat reprehenderit enim labore culpa sint ad nisi Lorem pariatur mollit ex esse exercitation amet. Nisi anim cupidatat excepteur officia. Reprehenderit nostrud nostrud ipsum Lorem est aliquip amet voluptate voluptate dolor minim nulla est proident. Nostrud officia pariatur ut officia. Sit irure elit esse ea nulla sunt ex occaecat reprehenderit commodo officia dolor Lorem duis laboris cupidatat officia voluptate. Culpa proident adipisicing id nulla nisi laboris ex in Lorem sunt duis officia eiusmod. Aliqua reprehenderit commodo ex non excepteur duis sunt velit enim. Voluptate laboris sint cupidatat ullamco ut ea consectetur et est culpa et culpa duis.
                  </InfoCard>
                </Grid>
              </FeatureFlagged>
              <Grid item xs={12}>
                <HomePageRecentlyVisited />
              </Grid>
            </Grid>
              <FeatureFlagged with="homepage-jira">
                <Grid item xs={8}>
                  {/* <KnaufJiraDashboard /> */}
                  <JiraUserIssuesCard />
                </Grid>
              </FeatureFlagged>
              <FeatureFlagged with="homepage-github">
                <Grid item xs={8}>
                  <HomePageYourOpenPullRequestsCard query="is:open is:pr assignee:@me archived:false"/>
                </Grid>
                <Grid item xs={8}>
                  <HomePageRequestedReviewsCard />
                </Grid>
              </FeatureFlagged>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
