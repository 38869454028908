import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 70,
  },
  path: {
    fill: '#00a0e6',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 143.4898"
    >
      <path
        className={classes.path}
        d="M0,116.3376h19.0242l5.3018-37.2717h1.8712l7.1731,37.2717h19.3361L43.974,71.1125 L63.31,31.1897H44.1299L27.6007,65.1865h-1.4034L35.3975,0H16.3733L0,116.3376z"
      />
    </svg>
  );
};

export default LogoIcon;
