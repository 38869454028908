import { TemplateBackstageLogoIcon } from '@backstage/plugin-home';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const tools = [
  {
    url: 'https://knaufjira.atlassian.net/wiki/spaces/DaaSP/overview?homepageId=3680797351',
    label: 'DaaS Confluence',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://github.com/Knauf-Group',
    label: 'Knauf-Group GitHub',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://one.eu.newrelic.com/',
    label: 'New Relic',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://eu1.anypoint.mulesoft.com/',
    label: 'Mulesoft',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://knauf-sonarqube.westeurope.cloudapp.azure.com/',
    label: 'SonarQube',
    icon: <TemplateBackstageLogoIcon />,
  },
];
