import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef, secretRouteRef } from './routes';

export const secretSharingPlugin = createPlugin({
  id: 'secret-sharing',
  routes: {
    root: rootRouteRef,
    secret: secretRouteRef,
  },
});

export const SecretSharingPage = secretSharingPlugin.provide(
  createRoutableExtension({
    name: 'SecretSharingPage',
    component: () =>
      import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
