import { createSubRouteRef, createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'secret-sharing',
});

export const secretRouteRef = createSubRouteRef({
  id: 'secret-sharing/secret',
  parent: rootRouteRef,
  path: '/secret/:secretId'
});
