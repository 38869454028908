import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const knaufTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      // Add custom colors here
      background: {
        default: '#f7f7f7',
        paper: '#fff',
      },
      primary: {
        main: '#0077c8',
        contrastText: '#fff',
      },
    },
  }),
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#00a0e6', '#003778'], shape: shapes.wave }),
    // TODO: leaving the rest commented out, to show possible header theming
    // documentation: genPageTheme({
    //   colors: ['#8c4351', '#343b58'],
    //   shape: shapes.wave2,
    // }),
    // tool: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.round }),
    // service: genPageTheme({
    //   colors: ['#8c4351', '#343b58'],
    //   shape: shapes.wave,
    // }),
    // website: genPageTheme({
    //   colors: ['#8c4351', '#343b58'],
    //   shape: shapes.wave,
    // }),
    // library: genPageTheme({
    //   colors: ['#8c4351', '#343b58'],
    //   shape: shapes.wave,
    // }),
    // other: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    // app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    // apis: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
  },
});
