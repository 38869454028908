import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { createApiRef } from '@backstage/core-plugin-api';

import {KnaufJiraApi, KnaufJiraApiClient} from './api';

export const knaufJiraApiRef = createApiRef<KnaufJiraApi>({
  id: 'plugin.knauf-jira-dashboard-api',
})

export type Issue = {
  key: string;
  summary: string;
  status: string;
};



export const knaufJiraDashboardPlugin = createPlugin({
  id: 'knauf-jira-dashboard',
  apis: [ 
    createApiFactory({
      api: knaufJiraApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) => {return new KnaufJiraApiClient({discoveryApi, fetchApi})},
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const KnaufJiraDashboardPage = knaufJiraDashboardPlugin.provide(
  createRoutableExtension({
    name: 'KnaufJiraDashboardPage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);

/**
 * Jira content exported from the Jira Dashboard plugin
 * @public */
export const KnaufJiraDashboard = knaufJiraDashboardPlugin.provide(
  createComponentExtension({
    name: 'KnaufJiraDashboard',
    component: {
      lazy: () =>
        import('./components/JiraHomepage').then(
          m => m.JiraHomepage,
        ),
    },
  }),
);

/**
 * Jira content exported from the Jira Dashboard plugin
 * @public */
export const JiraUserIssuesCard = knaufJiraDashboardPlugin.provide(
  createComponentExtension({
    name:  'JiraUserIssuesCard',
    component: {
      lazy: () =>
        import('./components/JiraUserIssuesCard').then(
          m => m.JiraUserIssuesCard,
        ),
    },
  }),
);