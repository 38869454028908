import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { KnaufJiraApi } from './JiraApi';
import { Issue } from '@axis-backstage/plugin-jira-dashboard-common';
import { ResponseError } from '@backstage/errors';

export class KnaufJiraApiClient implements KnaufJiraApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  async getUserIssues(maxResults: number): Promise<Issue[]> {
    //   return useAsync(async (): Promise<Issue[]> => {
    //     // Would use fetch in a real world example
    //     return exampleApiIssues.results;
    // }, []);

    const apiUrl = await this.discoveryApi.getBaseUrl('jira-dashboard');

    // Convert to query parameters
    // const searchParams = new URLSearchParams({
    //   maxResults: maxResults.toString(),
    // });
    const searchParams = new URLSearchParams({
      maxResults: maxResults.toString(),
    });

    const resp = await this.fetchApi.fetch(
      `${apiUrl}/dashboards/user-issues?${searchParams.toString()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!resp.ok) throw await ResponseError.fromResponse(resp);
    return resp.json();
  }
}
